import React from 'react';
import TypeAnimation from 'react-type-animation';

import './AboutMe.scss';
import myPhoto from '../../assets/images/avatar.svg';
import Button from '../Button/Button';

const AboutMe = () => {
  return (
    <section className="aboutMe" id="about">
      <h1 className="aboutMe__heading">My Story</h1>
      <div className="aboutMe__description">
        <div className="aboutMe__container-image">
          <img
            src={myPhoto}
            alt="Veranika Karpava"
            className="aboutMe__image"
          />
        </div>
        <div className="aboutMe__container-content">
          <div className="aboutMe__greeting">
            <TypeAnimation
              cursor={true}
              sequence={['Hi, there!', 2000, 'I am Veranika', 5000]}
              wrapper="p"
              repeat={Infinity}
              speed={1}
              className="aboutMe__title"
            />
            <p className="aboutMe__role">
              {' '}
              a Full Stack Developer based in Greater Vancouver Area, Canada
            </p>
          </div>
          <div className="aboutMe__content">
            <p className="aboutMe__text">
              From the moment I first jumped into web development, I discovered my true passion.
              <br />
              Every line of code I wrote felt important and inspired my drive.
              <br />
              Using HTML, CSS, JavaScript and React, I&rsquo;ve created web applications that aren&rsquo;t just pretty; they&rsquo;re easy to use, combining visually appeal with functionality.
              <br />
              Whether I&rsquo;m working on the front-end or back-end, I tackle each task with enthusiasm, whether it&rsquo;s launching new designs or enhancing customer support.
              <br />
              My journey is about more than just writing code; it&rsquo;s about making a real difference, helping users, supporting businesses, and staying on top of technology changes.
              <br />
              Now, with extensive experience and a passion for new challenges, I&rsquo;m ready for whatever comes next. <br />I&rsquo;m excited to keep pushing myself, exploring new possibilities, and making a positive impact, one line of code at a time.
            </p>
          </div>
          <div className="aboutMe__container-button">
            <Button
              type="primary"
              path="/veranika-karpava-full-stack-developer-resume.pdf"
              title="Resume"
              target="blank"
            />
            <Button
              type="primary"
              path="https://github.com/veranika-karpava"
              title="GitHub"
              target="blank"
            />
            <Button
              type="primary"
              path="https://www.linkedin.com/in/veranikakarpava"
              title="LinkedIn"
              target="blank"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutMe;
